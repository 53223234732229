<template>
 <div>
  <b-flix-card>
    <div slot="title">
      <b-flix-alert variant="info">{{ $tc('message.webhooks', 1) }}</b-flix-alert>
    </div>
    <p class="flix-html-small" v-html="info"></p>
    <a href="https://bookingflix.de/support/documentation/webhooks" target="_blank" class="flix-btn flix-btn-primary">{{ $t('message.webhooksInfoButton') }}</a>
  </b-flix-card>
 </div>
</template>
<script>
export default {
  name: 'webhookInfo',
  components: {},
  props: {},
  data () {
    return {
      info: this.$t('message.webhooksInfo')
    }
  },
  computed: {
  },
  mounted () {
    this.info = this.info.split('\n').join('<br>')
  },
  methods: {
  }
}
</script>
<style lang="sass" scoped>
</style>
